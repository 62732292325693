<template>
  <div class="my">
    <van-nav-bar
        title="我的"
        right-text="退出"
        right-arrow
        @click-right="logout">
    </van-nav-bar>
    <div class="wrapper">
      <div class="info" style="height: 65px;">
        <div class="left">
          <img class="avatar" :src="avatar" />
          <ul class="cell">
            <li><span style="font-size: 16px;">{{ username }}</span></li>
            <li v-if="roleKbn == 0"><span style="font-size: 14px;color:rgb(248 243 243);">履约:{{performancePoints}}</span></li>
            <li v-if="roleKbn == 1"><span style="font-size: 14px;color:rgb(248 243 243);">信誉:{{performancePoints}}</span></li>
          </ul>
        </div>
        <div class="right">
          <ul class="cell" style="width:100%;">
            <li><span style="font-size: 15px;">佣金：￥{{ commission }}</span></li>
            <li><span style="font-size: 14px;color:rgb(248 243 243);">结佣方式：月结</span></li>
          </ul>
        </div>
      </div>
      <div class="link-list" v-if="roleKbn == 0">
        <van-cell title="库存换货" :icon="icon_my_stock" is-link to="exchangeGoodsForRegionStock" style="padding: 15px 16px;" />
        <van-cell title="全部订单" :icon="icon_my_order" is-link to="allOrder" style="padding: 15px 16px;" />
      </div>
      <div class="link-list" v-if="roleKbn == 1">
        <van-cell title="区域设置" :icon="icon_region_setting" is-link to="regionSetting" style="padding: 15px 16px;" />
        <van-cell title="门店信息" :icon="icon_store_setting" is-link to="storeInfo" style="padding: 15px 16px;" />
        <van-cell title="门店换货" :icon="icon_replace_order" is-link to="exchangeGoods" style="padding: 15px 16px;" />
      </div>
    </div>
  </div>
</template>

<script>
import logoPng from "@/assets/logo.png";
import icon_my_stock from "@/assets/icon_my_stock.png";
import icon_my_order from "@/assets/icon_my_order.png";
import icon_region_setting from "@/assets/icon_region_setting.png";
import icon_store_setting from "@/assets/icon_store_setting.png";
import icon_replace_order from "@/assets/icon_replace_order.png";
import {getPersonalCenterInfo} from "../api/login";

export default {
  name: "My",
  components: {},
  data() {
    return {
      avatar: logoPng,
      icon_my_stock: icon_my_stock,
      icon_my_order: icon_my_order,
      icon_region_setting: icon_region_setting,
      icon_store_setting: icon_store_setting,
      icon_replace_order: icon_replace_order,
      username: '',
      commission: '',
      performancePoints: '',
      roleKbn: null,
    }
  },
  created() {
    let role = localStorage.getItem("role");
    if(role == 'region_manager') {
      this.roleKbn = 0;
    } else if(role == 'terminator') {
      this.roleKbn = 1;
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      getPersonalCenterInfo().then(response => {
        this.username = response.data.showName;
        this.commission = response.data.commission;
        this.performancePoints = response.data.performancePoints;
      });
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      this.$router.push({path: '/phoneInput'});
    }
  }
};
</script>
<style lang="scss" scoped>
.my {
  width: 100%;
  min-height: 300px;
  background: url("../assets/my_bg.png") no-repeat;
  background-size: 100% auto;
}
.wrapper {
  padding: 40px 20px 10px;
  color:#FFFFFF;
  text-align: left;
}
.left {
  float: left;
  width: 68%;
  text-align: left;
}
.right {
  float: right;
  width: 32%;
  text-align: left;
}
.avatar {
  background: #bdb5b5;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #f5f5f5;
  display: block;
  float: left;
}
.cell {
  display: block;
  width: calc(100% - 72px);
  float: right;
  height: 62px;
  line-height: 36px;
}
.link-list {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 15px;
}
.van-cell {
  background-color: transparent;
}
</style>
